import { useRecoilState } from 'recoil';
import {
  cartState,
  cartControllerState,
  canCheckoutProceedState,
  userCheckoutDataState,
  cardCheckoutDataState,
  triggerSubmit,
  paymentDataState,
  missingFieldErrorState,
  participantsState,
  challengeDetailsState,
  selectedAddressesState,
  cartAndStepsControllerState,
  shippingAddressToRenderState,
  discountValueState,
  notOwnSubscriptionState,
  optionalProductsState,
  splitPaymentState,
  usersElegibleForSplitPaymentState,
  preCheckoutCartState,
  isCartDrawerOpenState,
  kitsPurchaseModalState,
  productsPurchaseModalState,
  payerDataState,
  branchLinkState,
  ERPFildesComplete
} from '@source/recoil/atoms';

export const useCheckoutCart = () => {
  const [cart, setCart] = useRecoilState(cartState);
  return { cart, setCart };
};

export const useAddToCart = () => {
  const [cart, setCart] = useRecoilState(cartState);
  const newCart = [...cart];

  return product => {
    const foundIndex = cart.findIndex(item => item.id === product.id);

    if (foundIndex >= 0) {
      newCart[foundIndex] = {
        ...cart[foundIndex],
        quantity: cart[foundIndex].quantity + 1
      };

      return setCart(newCart);
    }

    setCart([...cart, { ...product, quantity: 1 }]);
  };
};

export const useRemoveFromCart = () => {
  const [cart, setCart] = useRecoilState(cartState);
  const newCart = [...cart];

  return product => {
    const foundIndex = cart.findIndex(item => item.id === product.id);

    if (foundIndex >= 0) {
      newCart[foundIndex] = {
        ...cart[foundIndex],
        quantity:
          cart[foundIndex].quantity === 0 ? 0 : cart[foundIndex].quantity - 1
      };

      return setCart(newCart);
    }

    return;
  };
};

export const useUpdateCartItem = () => {
  const [cart, setCart] = useRecoilState(cartState);
  const newCart = [...cart];

  return product => {
    const foundIndex = cart.findIndex(item => item.id === product.id);

    if (foundIndex >= 0) {
      newCart[foundIndex] = {
        ...cart[foundIndex],
        price: product.price
      };

      return setCart(newCart);
    }

    setCart([...cart, { ...product, quantity: 1 }]);
  };
};

export const useSetCartItem = () => {
  const [cart, setCart] = useRecoilState(cartState);
  const newCart = [...cart];

  return product => {
    const foundIndex = cart.findIndex(item => item.id === product.id);

    if (foundIndex === -1) {
      newCart.push({ ...product });
      return setCart(newCart);
    }
    newCart[foundIndex] = { ...product };
    setCart(newCart);
  };
};

export const useClearCart = () => {
  const [, setCart] = useRecoilState(cartState);

  return () => {
    setCart([]);
  };
};

export const useCartController = () => {
  const [cartController, setCartController] =
    useRecoilState(cartControllerState);

  return props => {
    const newObj = { ...cartController, ...props };
    return setCartController(newObj);
  };
};

// Controls the cart button
export const useCanCheckoutProceed = () => {
  const [canCheckoutProceed, setCanCheckoutProceed] = useRecoilState(
    canCheckoutProceedState
  );
  return { canCheckoutProceed, setCanCheckoutProceed };
};

export const useUserCheckoutData = () => {
  const [userCheckoutData, setUserCheckoutData] = useRecoilState(
    userCheckoutDataState
  );

  return { userCheckoutData, setUserCheckoutData };
};

export const useCardCheckoutData = () => {
  const [cardCheckoutData, setCardCheckoutData] = useRecoilState(
    cardCheckoutDataState
  );

  return { cardCheckoutData, setCardCheckoutData };
};

export const useTriggerSubmit = () => {
  const [submit, setSubmit] = useRecoilState(triggerSubmit);

  return { submit, setSubmit };
};

export const usePaymentData = () => {
  const [paymentData, setPaymentData] = useRecoilState(paymentDataState);

  return props => {
    const newObj = { ...paymentData, ...props };
    return setPaymentData(newObj);
  };
};

export const useMissingFieldError = () => {
  const [missingFieldError, setMissingFieldError] = useRecoilState(
    missingFieldErrorState
  );

  return { missingFieldError, setMissingFieldError };
};

export const useParticipants = () => {
  const [participants, setParticipants] = useRecoilState(participantsState);

  return { participants, setParticipants };
};

export const usePreCheckoutCart = () => {
  const [preCheckoutCart, setPreCheckoutCart] =
    useRecoilState(preCheckoutCartState);

  return { preCheckoutCart, setPreCheckoutCart };
};

export const useIsCartDrawerOpenState = () => {
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useRecoilState(
    isCartDrawerOpenState
  );

  return { isCartDrawerOpen, setIsCartDrawerOpen };
};

export const useOptionalProducts = () => {
  const [optionalProducts, setOptionalProducts] = useRecoilState(
    optionalProductsState
  );

  return { optionalProducts, setOptionalProducts };
};

export const useSplitPayments = () => {
  const [splitPayments, setSplitPayments] = useRecoilState(splitPaymentState);

  return { splitPayments, setSplitPayments };
};

export const usePayerData = () => {
  const [payerData, setPayerData] = useRecoilState(payerDataState);

  return { payerData, setPayerData };
};

export const useUsersElegibleForSplitPayment = () => {
  const [usersElegibleForSplitPayment, setUsersElegibleForSplitPayment] =
    useRecoilState(usersElegibleForSplitPaymentState);

  return { usersElegibleForSplitPayment, setUsersElegibleForSplitPayment };
};

export const useChallengeDetails = () => {
  const [challengeDetails, setChallengeDetails] = useRecoilState(
    challengeDetailsState
  );

  return { challengeDetails, setChallengeDetails };
};

export const useKitsPurchaseModal = () => {
  const [isOpenModalKits, setIsOpenModalKits] = useRecoilState(
    kitsPurchaseModalState
  );

  return { isOpenModalKits, setIsOpenModalKits };
};

export const useProductsPurchaseModal = () => {
  const [productsPurchaseModal, setProductsPurchaseModal] = useRecoilState(
    productsPurchaseModalState
  );

  return { productsPurchaseModal, setProductsPurchaseModal };
};

export const useSelectedAddresses = () => {
  const [selectedAddresses, setSelectedAddresses] = useRecoilState(
    selectedAddressesState
  );

  return { selectedAddresses, setSelectedAddresses };
};

export const useCartAndStepsController = () => {
  const [cartAndStepsController, setCartAndStepsControllers] = useRecoilState(
    cartAndStepsControllerState
  );

  return { cartAndStepsController, setCartAndStepsControllers };
};

export const useShippingAddressToRender = () => {
  const [shippingAddressToRender, setShippingAddressToRender] = useRecoilState(
    shippingAddressToRenderState
  );

  return { shippingAddressToRender, setShippingAddressToRender };
};

export const useDiscountValue = () => {
  const [discountValue, setDiscountValue] = useRecoilState(discountValueState);

  return { discountValue, setDiscountValue };
};

export const useNotOwnSubscription = () => {
  const [notOwnSubscription, setNotOwnSubscription] = useRecoilState(
    notOwnSubscriptionState
  );

  return { notOwnSubscription, setNotOwnSubscription };
};

export const useGetBranchLink = () => {
  const [branchLink, setBranchLink] = useRecoilState(branchLinkState);

  return { branchLink, setBranchLink };
};
export const useVerifyFildesErp = () => {
  const [isOkToSendERP, setIsOkToSendERP] = useRecoilState(ERPFildesComplete);

  return { isOkToSendERP, setIsOkToSendERP };
};
